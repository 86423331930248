<script setup lang="ts">
import Typewriter from 'typewriter-effect/dist/core'

const { strings, loop = false, delay = 30, deleteSpeed = 5 } = defineProps<{
  strings: string[]
  loop?: boolean
  delay?: number
  deleteSpeed?: number
}>()

const root = ref<HTMLElement | null>(null)

onMounted(() => {
  const typewriter = new Typewriter(root.value, {
    strings,
    loop,
    delay,
    deleteSpeed,
  })

  strings.forEach((string) => {
    typewriter.typeString(string).pauseFor(2500)

    if (strings.indexOf(string) === strings.length - 1)
      typewriter.start()
    else
      typewriter.deleteAll()
  })
})
</script>

<template>
  <div ref="root" />
</template>
